



































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import { BSpinner, BTable, BvTableField } from "bootstrap-vue";
import NetworksManagementModal from '@/modules/accounting/components/NetworksManagementModal.vue';
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import SygniNetworkPicker from '@/components/inputs/SygniNetworkPicker.vue';
import { Watch } from 'vue-property-decorator';
import breakpoints from '@/plugins/breakpoints';
import { mapGetters } from 'vuex';
import { Dictionaries } from '../../portfolio/store/types';
import { CheckboxOption } from '@/store/types';
import SygniProgressBar from '@/components/layout/SygniProgressBar.vue';
import LandingPageModal from './LandingPagePreviewModal.vue';
import _ from 'lodash';

@Component({
  components: {
    SygniCircleButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniNetworkPicker, NetworksManagementModal, SygniSquareButton, SygniRectButton, SygniModal, SygniProgressBar, LandingPageModal
  },
  computed: {
    ...mapGetters('portfolio', {
      dictionaries: 'getDictionaries',
    })
  }
})
export default class CampaignsTable extends SygniTable<any> {
  showNetworkManagementModal: boolean = false;
  showNetworkAccessModal: boolean = false;
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  deletingCampaignId: string | null = null;
  isLandingPageModalLoading: boolean = false;
  showLandingPageModal: boolean = false;
  notifiedCampaignId: string = null;
  dictionaries!: Dictionaries;
  isLoading: boolean = false;
  refresh: any = null;

  breakpoints = breakpoints;

  tableFields: (BvTableField & { borderless?: Boolean } & { key: string })[] = [
    { key: 'campaignName', sortable: true, class: 'table__head-cell left ', label: 'Name' },
    { key: 'issuerName', sortable: true, class: 'table__head-cell left', borderless: true, label: 'Issuer' },
    // { key: 'category', sortable: true, class: 'table__head-cell', borderless: true, label: 'Category' },
    { key: 'startDate', sortable: true, class: 'table__head-cell', borderless: true, label: 'Start' },
    { key: 'endDate', sortable: true, class: 'table__head-cell', borderless: true, label: 'End' },
    { key: 'investmentValue', sortable: true, class: 'table__head-cell text-right', label: 'Value' },
    { key: 'networks', sortable: false, class: 'table__head-cell networks', label: 'Networks' },
    { key: 'status', sortable: true, class: 'table__head-cell', label: 'Status' },
    { key: 'target', sortable: false, class: 'table__head-cell', label: 'Target' },
    { key: 'investmentReach', sortable: true, class: 'table__head-cell text-right', label: 'Investment reach' },
    { key: 'actions', sortable: false, class: 'table__head-cell', label: 'Actions' }
  ];

  get statusItems() {
    let statuses: Array<string> = _.uniq(this.items.map((el: any) => {
      return el.status.toUpperCase();
    }));

    return statuses;
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get formattedActionItems() {
    return this.statusItems.map((value: string, index: number) => {
      return {
        id: index + 1,
        key: value,
      }
    })
  }

  get campaignData() {
    return this.$store.getters['campaigns/getCampaignData']
  }

  async onRowClickHandler(item: any) {
    this.isLandingPageModalLoading = true;
    this.showLandingPageModal = true;
    try {
      const response = await this.$store.dispatch('campaigns/getCampaign', item.id);
      this.$store.commit('campaigns/setCampaignData', response)
    } catch(error) {
      this.showLandingPageModal = false;
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
    this.isLandingPageModalLoading = false;
  }

  closeLandingPageModal() {
    this.isLandingPageModalLoading = false;
    this.showLandingPageModal = false;
    this.$store.commit('campaigns/clearCampaignData');
  }
  
  clearTableSelection() {
    this.selectAllRowsBoolean = false;
    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = false;
    });
  }

  toggleAllRowsAction() {
    if (this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if (this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.campaignsTable as BTable).$el.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;
      (this.$refs.campaignsTable as BTable).selectAllRows();
    } else {
      (this.$refs.campaignsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach((row: any) => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  enableNetworkManagementModal() {
    this.showNetworkManagementModal = true;
  }

  closeNetworkManagementModal() {
    this.showNetworkManagementModal = false;
  }

  enableNetworkAccessModal(network: any) {
    this.sharingNetwork = network;
    this.showNetworkAccessModal = true;
  }

  closeNetworkAccessModal() {
    this.showNetworkAccessModal = false;
    this.sharingNetwork = null;
  }

  async refreshTable() {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$nextTick(async () => {
      await this.getItems();
    })
  }

  getStatusClassName(status: string): string {
    let className = 'success';
    switch(status.toUpperCase()) {
      case('FINISHED'):
        className = 'disabled';
        break;
      case('ARCHIVED'): 
        className = 'danger';
        break;
      case('ACTIVE'):
        className = 'primary';
        break;
    }

    return className;
  }

  statusText(status: string): string {
    return this.$options.filters.capitalizeFirstLetter(status.toLowerCase());
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('campaigns/setCampaignsTableFiltersQuery', filtersQuery);
    if (this.paginationInstance) this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      this.$store.commit('campaigns/setCampaignsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    if (this.paginationInstance) this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      this.$store.commit('campaigns/setCampaignsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  setQuery() {
    this.$store.commit('campaigns/setCampaignsTableQuery', this.localTableQuery);
  }

  getIndustryLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.industry.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getStageLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.stage.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getInstrumentClassLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.instrumentClass.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getInstrumentTypeLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.instrumentType.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('company') ? true : false;
  }

  get viewedId() {
    if(this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company' ? this.viewedCompanyId : '';
    } else {
      return this.activeUserData.context.context !== 'fund' ? this.viewedFundId : '';
    }
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows?.length;
  }

  openDeleteConfirmationModal(campaignId: string) {
    this.deletingCampaignId = campaignId;
  }

  closeDeleteConfirmationModal() {
    this.deletingCampaignId = null;
  }

  async activateCampaign(campaignId: string) {
    try {
      this.$store.commit(this.setTableBusyMutation, true);
      await this.$store.dispatch('campaigns/markCampaignAsActive', campaignId);
      await this.refreshTable();

      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Campaign activated'
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
      this.$store.commit(this.setTableBusyMutation, false);
    }
  }

  async deleteCampaign() {
    try {
      this.$store.commit(this.setTableBusyMutation, true);
      this.isLoading = true;
      await this.$store.dispatch('campaigns/archiveCampaign', this.deletingCampaignId);
      this.closeDeleteConfirmationModal();
      await this.refreshTable();

      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Campaign archived'
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
      this.$store.commit(this.setTableBusyMutation, false);
    }

    this.isLoading = false;
  }

  async finishCampaign(campaignId: string) {
    try {
      this.$store.commit(this.setTableBusyMutation, true);
      await this.$store.dispatch('campaigns/finishCampaign', campaignId);
      this.closeDeleteConfirmationModal();
      await this.refreshTable();

      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Campaign finished'
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
      this.$store.commit(this.setTableBusyMutation, false);
    }
  }

  openNotificationModal(campaignId: string) {
    this.notifiedCampaignId = campaignId
  }

  closeNotificationModal() {
    this.notifiedCampaignId = null
  }

  async notifyBrokers(campaignId: string) {
    this.$store.commit(this.setTableBusyMutation, true);
    this.isLoading = true
    
    try {
      await this.$store.dispatch('campaigns/notifyBrokers', campaignId);
      await this.refreshTable();

      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Brokers notified'
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.closeNotificationModal();
    this.$store.commit(this.setTableBusyMutation, false);
    this.isLoading = false;
  }

  async draftCampaign(campaignId: string) {
    try {
      this.$store.commit(this.setTableBusyMutation, true);
      await this.$store.dispatch('campaigns/markCampaignAsDraft', campaignId);
      await this.refreshTable();

      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Campaign marked as draft'
      })
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
      this.$store.commit(this.setTableBusyMutation, false);
    }
  }

  async getItems() {
    await this.$store.dispatch(this.$attrs.getItemsAction, '');
  }

  setWidths() {
    const columns: Array<number> = [];
    (this.$refs.campaignsTable as CampaignsTable).$el.querySelectorAll('thead tr .table__head-cell').forEach((column: any) => {
      columns.push(column.offsetWidth);
    })
    this.$nextTick(() => {
      const dataRows = (this.$refs.campaignsTable as CampaignsTable).$el.querySelectorAll('.b-table-details tbody tr');

      dataRows.forEach((dataRow: any) => {
        columns.forEach((width: any, index: number) => {
          if (index == 0) return;
          const element: HTMLElement = dataRow.querySelector(`td:nth-of-type(${index + 1})`);
          element.style.width = `${(index + 1) == columns.length ? width - 20 : width}px`;
        })
      })

    })
  }

  get filtersQuery() {
    return this.$store.getters['campaigns/getCampaignsTableFiltersQuery'];
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  async beforeMount() {
    await this.getItems();
    this.onMounted();
    this.setBorderlessTds();
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    const selectedRowEl = (this.$refs.campaignsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement).offsetTop + (selectedRowEl as HTMLDivElement).offsetHeight - 20;

    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if (this.isProcessing) {
      this.$store.commit('campaigns/setCampaignsTableBusy', true);
      this.refresh = setTimeout(() => {
        this.onFiltersChange(this.filtersQuery);
      }, 3000);
    }
  }

  @Watch('breakpoints.w') onBreakpointsChange(): void {
    this.setWidths();
  }
}

